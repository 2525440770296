<template>
  <div>
    <b-row>
      <b-col cols="12" class="px-xl-2 mx-auto">
        <!-- form -->
        <validation-observer ref="mailForm" #default="{ invalid }">
          <b-row>
            <b-col cols="12" v-if="load">
              <center v-if="load">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </center>
            </b-col>
            <b-col cols="12" v-else>
              <validation-observer class="w-100" ref="TopicForm" #default="{invalid}">
                <b-row>
                  <b-col md="12" class="mb-2">
                    <validation-provider
                      #default="{ errors }"
                      name="type"
                      vid="type"
                      rules="required"
                    >
                      <b-form-group label="Invitation Type *" label-for="type_id">
                        <v-select
                          v-model="type"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          :options="types"
                          id="type_id"
                          class="w-100 mb-0 custom-field"
                          :reduce="(val) => val.id"
                          placeholder="Select Type"
                          :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
                        >
                          <template
                            v-slot:option="option"
                          >{{ $i18n.locale == "ar" ? option.name_local : option.name }}</template>
                          <template #selected-option="{ name, name_local }">
                            <div style="display: flex; align-items: baseline">
                              <strong v-if="$i18n.locale == 'ar'">{{ name_local }}</strong>
                              <strong v-else>{{ name }}</strong>
                            </div>
                          </template>
                          <template #no-options>{{ $t("noMatching") }}</template>
                        </v-select>
                        <small class="text-danger float-left">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" v-if="type == 1">
                    <validation-provider
                      #default="{ errors }"
                      name="E-Mails"
                      :vid="`emails`"
                      rules="required"
                    >
                      <b-form-group label="E-Mails *" label-for="emails">
                        <b-form-tags
                          input-id="emails"
                          v-model="formMail.emails"
                          placeholder="Example_1@gmail.com,Example_2@gmail.com,.."
                          class="custom-field"
                        ></b-form-tags>
                        <small class="text-danger float-left">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" v-if="type == 2">
                    <b-row>
                      <b-col cols="10">
                        <validation-provider
                          #default="{ errors }"
                          name="Upload File"
                          :vid="`formFile`"
                          rules="required"
                        >
                          <b-form-group label="Upload File *" label-for="formFile">
                            <b-form-file
                              id="formFile"
                              v-model="importFile"
                              :state="Boolean(importFile)"
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              class="custom-field"
                              placeholder="Upload File"
                            />
                            <small class="text-danger float-left">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="2" class="d-flex align-items-center">
                        <b-button
                          v-b-tooltip.hover.top="'Download Bulk Template'"
                          @click="ExportBulkTemplate"
                          class="shadow-none"
                          style="background-color: #e84185 !important"
                        >
                          <feather-icon class="font-weight-bolder" icon="DownloadIcon" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-col>
          </b-row>
          <b-form class="auth-login-form mt-2" @submit.prevent="sendInvitation" autocomplete="off">
            <!-- submit buttons -->
            <b-button
              type="submit"
              class="rounded-pill shadow-none"
              :disabled="load"
              style="background-color: #e84185 !important"
              block
            >
              Send
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BCard,
  BForm,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BFormFile,
  BCardTitle,
  BCardBody,
  BFormRadioGroup,
  BFormRadio,
  BSpinner,
  BFormTags,
  VBTooltip
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { helpers } from "@vuelidate/validators";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, regex } from "@validations";
import store from "@/store/index";
import vSelect from "vue-select";
import { mapGetters } from "vuex"

export default {
  components: {
    required,
    email,
    regex,
    BFormGroup,
    BFormInput,
    BRow,
    BForm,
    BCol,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormRadioGroup,
    BFormRadio,
    VBTooltip,
    BFormTags,
    BSpinner,
    vSelect
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  validations(value) {
    const multiEmail = helpers.withMessage(
      "Invalid email format or missing email(s). Please separate emails with commas.",
      value => {
        if (!value) return true;
        const emails = value.split(",").map(email => email.trim());
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emails.every(email => emailPattern.test(email));
      }
    );

    return {
      formMail: {
        emails: { required, multiEmail }
      }
    };
  },
  computed: {
    ...mapGetters({
      load: "admins/load",
    }),
  },
  data() {
    return {
      types: [
        { id: 1, name: "Input E-Mails" },
        { id: 2, name: "Upload File With E-Mails" }
      ],
      type: null,
      selectedOption: "email",
      importFile: null,
      formMail: {
        emails: ""
      },
      items: [
        { title: "School 1", students: "50 Student" },
        { title: "School 2", students: "80 Student" },
        { title: "School 3", students: "30 Student" },
        { title: "School 4", students: "20 Student" },
        { title: "School 5", students: "35 Student" }
      ]
    };
  },
  methods: {
    multiEmailValidator(value) {
      if (!value) return true;
      const emails = value.split(",").map(email => email.trim());
      return emails.every(email =>
        email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
      );
    },
    changeFile(e) {
      this.importFile = this.$refs.refInputEl.files[0];
    },
    ExportBulkTemplate() {
      this.$store
        .dispatch("admins/exportBulkTemplate")
        .then(res => {
          this.$swal({
            icon: "success",
            title: `<h4>Downloaded</h4>`,
            showConfirmButton: false,
            timer: 2500
          });
        })
        .catch(error => {
          if (error.response) {
            this.$swal({
              icon: "error",
              title: `<h4>${error.response.data.message}</h4>`,
              showConfirmButton: false,
              timer: 2500
            });
          }
        });
    },
    sendInvitation() {
      if (this.type == 2) {
        this.loading = true;
        const bulkFile = new FormData();
        bulkFile.append("file", this.importFile);
        this.$store
          .dispatch("admins/bulkInvitation", bulkFile)
          .then(res => {
            // this.importFile = null
            this.$refs.mailForm.reset()
            this.importFile = null,
            this.formMail = {
              emails: ""
            },
            this.$emit("uploaded");
            this.$swal({
              icon: "success",
              title: `<h4>Your invitation has been sent</h4>`,
              showConfirmButton: false,
              timer: 2500
            });
          })
          .catch(error => {
            if (error.response) {
              const errors = error.response.data.errors;
              const errorMessages = Object.values(errors).flat().map((err) => `<li>${err}</li>`)
                .join("");
              this.$swal({
                icon: "error",
                title: `<h4>${errorMessages}</h4>`,
                showConfirmButton: true,
                confirmButtonColor: '#E84185',
                allowOutsideClick: true,
                customClass: {
                  popup: "swal-large", 
                },
              });
            }
          });
      } else {
        this.$refs.mailForm.validate().then(success => {
          if (success) {
            this.$store
              .dispatch("admins/sendInvitation", this.formMail)
              .then(res => {
                this.$refs.mailForm.reset()
                this.importFile = null,
                this.formMail = {
                  emails: ""
                },
                this.$swal({
                  icon: "success",
                  title: `<h4>${res.message}</h4>`,
                  showConfirmButton: false,
                  timer: 2500
                });
              })
              .catch(error => {
                if (error.response) {
                  this.$swal({
                    icon: "error",
                    title: `<h4>${error.response.data.message}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: '#E84185',
                    allowOutsideClick: true,
                    customClass: {
                      popup: "swal-large", 
                    },
                  });
                }
              });
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.custom-control-label {
  width: 100% !important;
}
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
